// Application-wide styling.

.dx-switch {
    width: 65px;
}

.dx-switch-handle {
    margin-left: 0px;
}

.active-checkbox {
    .dx-datagrid-checkbox-size {
        .dx-checkbox-container {
            .dx-checkbox-icon {
                font-size: 18px;
                border-color: transparent;
                background-color: transparent;
            }
        }
    }
}

.bank-holidays_container {
    margin-left: 20px;
    margin-right: 20px;
}

.bank-holidays_edit {
    .dx-field-item-label {
        width: 40%;
    }
    .dx-layout-manager .dx-label-h-align.dx-flex-layout .dx-field-item-content {
        width: 60%;
    }
}

.zip_code_range_container {
    margin-left: 20px;
    margin-right: 20px;
}

.zipcode-range_edit {
    .dx-field-item-label {
        width: 40%;
    }
    .dx-layout-manager .dx-label-h-align.dx-flex-layout .dx-field-item-content {
        width: 60%;
    }
}
.time-series-input {
    display: flex;
    margin-top: 2%;
    justify-content: center;
    width: auto;
}
